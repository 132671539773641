

@font-face {
  font-family: 'Default';
  src: url('/fonts/poppins_regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Default';
  src: url('/fonts/poppins_regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face { 
  font-family: 'Default';
  src: url('/fonts/poppins_bold.woff2');
  font-weight: 700;
  font-style: normal;
  }
  
@font-face {
  font-family: 'Default';
  src: url('/fonts/poppins_italic.woff2');
  font-weight: normal;
  font-style: italic;
}


@font-face {
	font-family: "Avenir Pro";
	src: url("/fonts/AvenirProRegular.woff")
}

@font-face {
	font-family: "ApercuMono";
	src: url("/fonts/ApercuMono.woff")
}

@font-face {
	font-family: "Brown";
	src: url("/fonts/BrownRegular.woff")
}

@font-face {
	font-family: "Brown Light";
	src: url("/fonts/BrownLight.woff")
}

@font-face {
	font-family: "Brown Bold";
	src: url("/fonts/BrownBold.woff")
}

.grecaptcha-badge { visibility: hidden; }

.bm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.bm-parallax{
  background-attachment: fixed;
  background-size: cover;
}

.bm-img{
  max-width: 100%;
  height: auto;
}


.mediumTable td {
  font-size: 11px !important;
  padding: 4px 0px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}




.event-indicators-wrapper-resp {
  container-type: inline-size;
  height: 100%;
}

.event-indicators-resp {
  width: 100%;
  display: block;
}

.event-indicators-resp-small {
  display: none;
}

.event-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 5px;
}

.event-indicators-wrapper{
  height: 100%;
}

@container (max-width: 59px) {
  .event-indicators-resp {
    display: none;
  }

  .event-indicators-resp-small {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 5px;
  }
}


.mediumTable th {
  font-size: 13px;
  overflow: hidden;
  color: #555 !important;
  font-weight: normal !important;
  padding: 4px 3px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

.mediumTable td {
  font-size: 11px !important;
  padding: 4px 2px !important;

  background-color: #fff !important;
}

.mediumTable .ant-table-footer {
  padding: 0px !important;
  background-color: #fafafa !important;
}

.mediumTable .ant-table-title {
  padding: 0px !important;
  background-color: #fafafa !important;
}



.nb button {
  /* Reset all default styles */
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  cursor: pointer;
  outline: none; /* Remove default outline */
}

.nb button:hover,
.nb button:focus,
.nb button:active {
margin: 0;
padding: 0;
border: none;
background: none;
color: inherit;
font: inherit;
line-height: normal;
cursor: pointer;
outline: none; /* Remove default outline */
}

@supports not (-webkit-overflow-scrolling: touch){ /* CSS for other than iOS devices */ 
  .bm-parallax{
    background-attachment: fixed;
    background-size: cover !important;
  }  
}

@supports (-webkit-overflow-scrolling: touch) { /* CSS specific to iOS devices */
  .bm-parallax{

    background-attachment: fixed;
    background-size: cover;
    

    /* background-attachment:unset;
    background-size: cover;
    background-position: 50% 0 !important; */
  }  
}

.global-postal-code{
  border:1px solid #ccc;
  width: 300px;
  padding: 9px 12px;
  font-family: sans-serif;
  font-size: 13px;
}


.global-postal-code:focus-visible{
  outline: none !important;
  border: 1px solid #71C5E8;
  box-shadow: 0 0 4px 0 #71C5E8 inset;
}

.bm-img-link{
  opacity: 1;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.bm-img-link:hover{
  opacity: 0.7;
  transition: opacity 0.3s ease-out;
}

.bm-txt-link{
  opacity: 1;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
  /* color: inherit;  */
}

.bm-txt-link:hover{
  opacity: 0.7;
  transition: opacity 0.3s ease-out;
  text-decoration: underline;
}


#bm-notification{
  z-index: 13;
  position: relative;
}
#bm-notification p{
  margin-block-end: 0;
}

.bm-component h1, .bm-component h2, .bm-component h3, .bm-component h4{
  margin-bottom: 0px;
  font-weight: 500;
  line-height: 1.5715;
}

.bm-component .ant-space{
  flex-wrap: wrap;
}

.bm-component h1{
  font-size: 50px;
}

.bm-component h2{
  font-size: 40px;
}

.bm-component h3{
  font-size: 30px;
}

.bm-component h4{
  font-size: 20px;
}

.preview-container {
  max-width: 1248px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
}

.child-preview-container {
  max-width: 1248px;
  margin: 0 auto;
  overflow: hidden;
}




.carousel-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-image {
  /* opacity: 0; */
  transition: opacity 1.5s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-image.active {
  /* opacity: 1; */
}


.carousel.fade-transition .carousel-image {
  opacity: 0;
  transition: opacity 1.5s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel.fade-transition .carousel-image.active {
  opacity: 1;
  z-index: 1;
}

.carousel.scroll-transition .carousel-image-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}

.carousel.scroll-transition .carousel-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translateX(-100%);
  transition: transform 0.5s;
}

.carousel.scroll-transition .carousel-image-container .carousel-image.active {
  opacity: 1;
  transform: translateX(0);
}

.carousel.scroll-transition .carousel-image-container .carousel-image.previous {
  opacity: 1;
  transform: translateX(-100%);
}

.carousel.scroll-transition .carousel-image-container .carousel-image.next {
  opacity: 1;
  transform: translateX(100%);
}

.carousel.scroll-transition .carousel-image-container .carousel-image {
  opacity: 0;
  transform: translateX(100%);
}

.carousel.scroll-transition .carousel-image-container .carousel-image.hide {
  opacity: 0;
  transform: translateX(100%);
}


.underline:hover{
  text-decoration: underline;
}



.header_border .ant-select-selector{
  border: none !important;
  height: 40px !important;
  font-size: 16px;
}

.header_border .ant-select-selection-item{
  line-height: 38px !important;
}

.header_search .ant-input-search-button{
 height: 40px;
 width: 40px;
}

.header_search input{
  height: 40px !important;
  font-size: 16px;
}

.checkout-btn{
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
}

/* 
.notab{
  outline: none !important;
}

.tab:focus-visible{
  outline: auto !important;
  outline-color: #000 !important;
}

.tab:focus-within{
  outline: auto !important;
  outline-color: magenta !important;
} 

 .tab{
  outline: auto !important;
  outline-color: magenta !important;
}

:focus{
  outline: auto !important;
  outline-color: magenta !important;
} */


/*
CONTENT CREATOR
*/

.bm-footer-flex{
  flex-direction: row;
  gap: 50px !important;
}

.bm-footer-container {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
}


/*
END CONTENT CREATOR
*/

.ant-btn-danger:hover{
  background-color: #000 !important;
  border: 1px solid #000;
}
.preorder-hover:hover{
  background-color: #000 !important;
}

.ant-dropdown-menu-item-group-title{
  padding: 0px;
}
.ant-dropdown-menu-item-group-list{
  margin: 0px;
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary){
  border-left-color:#fff;
}

.header_search .ant-input, .header_search .ant-input:hover, .header_search .ant-input:focus{

  box-shadow: none;
  border-left: 0px;
  border-right: 0px;
  --antd-wave-shadow-color: none;
}

.header_search .ant-btn, .header_search .ant-btn:hover, .header_search .ant-btn:focus{

  box-shadow: none;
  --antd-wave-shadow-color: none;
  border-left: 0px;
 
}


.interact .ant-picker-content th{
  color: #1f1f1f;
  font-weight: normal;
}

.interact .ant-picker-content thead{
  background: none;
}

.interact .ant-picker-cell-in-view {
  pointer-events: all;
  color: #1f1f1f;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.addressItem{
  width: 100%;
  
}


.imgbox{

  margin: 0 auto;
  width: 130px;
  height: 180px;
  object-fit: contain;
  object-position: 50% 50%;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  
  
  /* filter:drop-shadow(0px 3px 4px rgba(0,0,0,0.05)); */
}


.imgplaceholder {
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;  
  text-align: center;
  top: 20px;
  width: 100px;
  height: 150px;
  display: block;
  position: relative;
  overflow: hidden;
  
}



.imgplaceholder:before { 
  content: attr(alt);
  display: block;
  height: 120px;
  overflow: hidden;
  margin-bottom: 10px;
}

.imgplaceholder:after { 
  content: "(url: " attr(src) ")";
  display: block;
  font-size: 12px;
}

.ant-input::placeholder{
  color: #666;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* 
.imgplaceholder{
  display: none;
  width: 120px;
  height: 180px;
  padding:10px;
  background-color: #fff;
  color: #1f1f1f;
}
.imgplaceholder_visible{
  display: block;
  width: 120px;
  height: 180px;
  padding:10px;
  background-color: #1f1f1f;
  color: #fff;
} */





.object-svg{
 color: rgba(0, 0, 0, 0.0);
}

.ant-select-selection__placeholder{
  color : blue;
}


.html a{
  color: #069;
}

.html a:hover{
  color: #3ba4d9;
  text-decoration: underline;
}


.ant-menu-submenu-arrow{
  display: none;
}

.gallery-img{
  max-width: 810px;
  max-height: 466px;
  margin: 0 auto;
  object-fit: contain;
  object-position: 50% 50%;
}

.gallery-vert{
  width: 60px;
  height: 90px;
  border: 1px solid #fafafa;
  margin: 0 auto;
  cursor: pointer;
  object-fit: cover;
  object-position: 50% 50%;
}

.gallery-thumb{
  width: 104px;
  height: 63px;
  border: 2px solid #AAA;
  margin: 0 auto;
  object-fit: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  object-position: 50% 50%;
}

.gallery-thumb-slider{
  margin: 10px 50px;
}

.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab{
  padding: 3px 16px;
}


/* .ant-btn-selected{
background-color: #eee;
}

.ant-btn-selected:focus{
  background-color: #eee;
  } */

.ant-input-number:hover {
  border-color: #aaa;
  box-shadow: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff;
}

.ant-input-affix-wrapper-focused {
  box-shadow:none;
}

.left_list_title{
  display: block;
  padding: 10px 10px;
  font-size: 24px;
}

.browsefilters .ant-collapse-item-active{
  font-weight: bold !important;
}

.browsefilters .ant-collapse-item:hover{
  font-weight: bold !important;
}

.browsefilters .ant-collapse-content-box{
  border-bottom: 1px solid #eee;
}

.browsefilters .ant-collapse-header{
  border-bottom: 1px solid #eee;
}

.browsefilters .ant-collapse-item-active .ant-collapse-header{
  border-bottom: none;
}

.flip .flipArrow{
  transform: scaleY(-1);
}




.tallBtn{
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  height: 46px;
  padding-bottom:10px;
}
.tallBtn em{
  display: block;
  line-height:10px;
  font-size: 12px;
}


.centered{
  text-align: center;
}

.featured_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.vcenter{
  display: flex;
  align-items: center;
}

 .leaflet-container {
  width: 100%;
  height: 100vh;
}

.contact_hours{
  max-width: 400px;
  text-align: left;
  margin: 0 auto;
}
.contact_hours table{
  width: 100%;
}

.contact_hours .title{
  font-size: 1.4em;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
}

.contact_hours .upcoming{
  font-size: 1.4em;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  height: 60px;
}

.contact_title {
  margin: 0 auto;
  font-size: 6em;
  text-align: center;
}

.contact_address {
  font-size: 2em;
  text-align: center;
  padding: 20px;
  padding-top: 40px;
}

.contact_box{
  text-align: center;
  background-color:rgb(32,32,32,0.9); 
  color: #fff;
  margin-bottom: 40px;
  margin-top: 30px;
}
.contact_box a {
  color: #fff;
}
.contact_phone{
  font-size: 4em;
  font-weight: normal;
}
.contact_toll_free{
  font-size: 2em;
  font-weight: normal;
}
.contact_email{
  font-size: 2em;
  font-weight: normal;
}
/* Stack Menu */

.stack-menu{
  max-width: 1248px;
  margin: 0 auto;
  background-color: #f5f5f5;

}

.stack-menu div{

  padding: 15px 20px; 
  display: inline-block;
  background-color: #eee;
  height: 46px;

}


.bm-cal table{
  border-collapse:collapse !important;
  border-spacing: 0px;
}

.bm-cal .eventCell {
  min-width: 10px;
  aspect-ratio: 3/4;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #eee;
  color: #000;
  margin: 0px;
  padding: 1px 4px;
  pointer-events: none;
  border: none;
}

.bm-cal .eventCellActive {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 10px;
  aspect-ratio: 3/4;
  background-color: #eee;
  color: #000;
  margin: 0px;
  padding: 1px 4px;
  border: none;
  pointer-events: all;
}



.bm-cal .ant-picker-cell{
  opacity: 1;
  color: #fff;
  pointer-events: none;
}

.bm-cal .ant-picker-cell-in-view{
  pointer-events: none;
  opacity: 1;
  display: table-cell;
}

.bm-cal .ant-picker-calendar .ant-picker-panel .ant-picker-body{
  background-color: transparent !important;
  padding:0px;
}

.bm-cal .ant-picker-calendar-full .ant-picker-panel{
  background-color: transparent !important;
}

.bm-cal .ant-picker-body tbody{
  border: 0px solid transparent;
}

.bm-cal .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content{
  background-color: transparent !important;
  height: 22px;
}
.bm-cal .ant-picker-calendar{
  background: transparent !important;

}

.bm-cal .ant-picker-cell-inner{
  background-color: none;
}
.bm-cal .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date{
  margin: 4px;
  background-color: transparent !important;
}

.bm-cal .ant-picker-content thead{
  display: none;
}

.bm-cal .ant-picker-content th{
  color: inherit !important;
}

.bm-cal .ant-picker-content td{
  padding: 2px;
}

.bm-cal .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th{
  margin:0px;
  height: inherit;
  text-align: center;
  line-height: inherit;
  text-transform: uppercase;
  padding: 4px 4px;
}

.bm-cal .cluster{
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  text-align: center;;
  margin-top: -11px;
}

.bm-cal .dot{
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 2px;
  border-radius: 3px;
  background-color: #000;
}

/* 
.bm-cal .ant-picker-calendar{
  background: transparent;
}

.bm-cal .calHeader{
  background-color: transparent;
  padding: 0px 0px;
  padding-top: 0px;
}

.bm-cal .ant-picker-calendar .ant-picker-panel .ant-picker-body{
  background: transparent;
}

.bm-cal .ant-picker-calendar-full .ant-picker-panel{
  background: transparent;
}

.bm-cal th{
  height: 2px !important;
  overflow: hidden;
  opacity: 0;
}

.bm-cal .eventCell{
  border-color: transparent;
  aspect-ratio: 4/3;
  min-width: auto;
  min-height: auto;
 
}

.bm-cal .eventCellActive{
  border-color: transparent;
  aspect-ratio: 4/3;
  min-width: auto;
  min-height: auto;
}
 */



/* Calandar */




.eventCell{
  /* min-width: 10px;
  min-height: 50px; */
  background-color: #eee;
  color: #000;
  /* margin: 2px; */
  pointer-events: none;
  border:1px solid #5ea9b1;
}
.eventCellActive{
  /* min-width: 10px;
  min-height: 50px; */
  color: #f2eddd;
  background-color: #1b4b59;
  /* margin: 2px; */
  pointer-events: all;
  border:1px solid #5ea9b1;
  transition-duration: 0.5s;
}

.eventCellActive::before {
  content: ''; /* Necessary to create the pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent overlay color */
  opacity: 0; /* Initially invisible */
  transition: opacity 0.3s; /* Smooth transition effect */
  pointer-events: none; /* Ensures the overlay doesn't interfere with pointer events */
}

.eventCellActive:hover::before {
  opacity: 1; /* Fully visible on hover */
}




.calHeader{

 
}

.caldate {
  text-align: left;
  width: 100%;
  display: block;
  padding: 0 3px;
}

.calHeader svg{
  fill:#f2eddd;
}

.calHeader h2{
  color:#f2eddd;
  font-size: 22px;
  font-weight: normal;
  text-align: center;
}

.ant-picker-cell{
  opacity: 0.1;
  color: #fff;
  pointer-events: none;

}
.ant-picker-cell-in-view{
  pointer-events: none;
  opacity: 1;
  display: table-cell;
  color: #5ea9b1;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body{
  /* background-color: #5ea9b1; */
  padding: 0px;
}

.ant-picker-body tbody{
  border: 1px solid transparent;
  
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content{
  height: 22px;
}

.ant-picker-cell-inner{
  background-color: #fff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date{
  margin: 4px;

}

.ant-picker-content thead{
  background-color: #1b4b59;

}

.ant-picker-content th{
  color: #f2eddd;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th{
  margin:0px;
  height: inherit;
  text-align: center;
  line-height: inherit;
  text-transform: uppercase;
  padding: 4px 4px;
}

.ant-form-item-explain.ant-form-item-explain-error{
font-size: 12px;
}

.ant-form-item-explain, .ant-form-item-extra, .ant-empty-description, .ant-card-meta-description{
  color: #000000d9;
}

.cal_arrow {
  transform: scale(1);
  transition: transform 0s ease-in-out; /* Smooth transition on transform */
}

.cal_arrow:hover {
  transform: scale(1.1); /* Scale to 1.2 times the original size on hover */
}


.ant-tree .ant-tree-switcher{
  width: 0px;
}
.ant-tree .ant-tree-node-content-wrapper:hover{
  background: inherit;
}
.ant-tree .ant-tree-node-content-wrapper{
  padding-top: 4px;
  padding-left: 0px;
}
.ant-collapse-content > .ant-collapse-content-box{
  padding: 0px;
  }
  


.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle{
  width: 24px;
  padding-right: 8px;
}


.ant-checkbox-checked span {
  background-color: #e00;
}

/* Custom */
.ant-btn-icon-only {
  width: 30px;
}
/* .ant-layout-sider{
  margin-bottom: 20px;
} */

.flipH{
  transform:scale(-1, 1);
}


.eventCard{
  text-align: center;
  padding: 10px;
  margin: 0px;
  padding-bottom: 0px;
  line-height: 20px;
}
.eventCardWrap h3{
  border-bottom: 1px solid #ccc;
}

.eventCardWrap{
  border: 1px solid #fff;
  background-color: #fff;
  padding-bottom: 10px;
}
.eventCardWrap button{
  margin: 0px;
  height: 22px;
  padding:0px;
}
.eventCard small{
  font-weight: normal;
}

.ant-collapse-content > .ant-collapse-content-box{

  padding-left: 10px;
  padding-right: 0px;

}

.ant-btn-link{
  margin: 0px;
  padding: 0px;
}
/* A:active, A:link, A:visited{

  
} */
/* .ant-btn:active, .ant-btn:focus, .ant-btn:hover{
  color: inherit;
} */


.shim{
  display: block;
  height: 5px;
}


.filterTag{
  background-color: #fff;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 10px;
}

/* gallery */



.gallery .img{
  background-color: #fff;
  width: "100%";
  height: 500px;
  background-repeat: no-repeat;
  background-size: contain; 
  background-position: center;

}


/* scrollbar */

.indent {
  border-left: 1px dashed #eee;
  padding-left: 10px;
  font-size: 13px;
}

.indent span {
  font-size: 12px;
}
.indent span.count {
  position: relative;
  right:0px;
  font-size: 10px;
}

/* width */
.sidebar ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.sidebar ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidebar ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.sidebar ::-webkit-scrollbar-thumb:hover {
  background: #555;
}




/* width */
.newsidebar ::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.newsidebar ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.newsidebar ::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
.newsidebar ::-webkit-scrollbar-thumb:hover {
  background: #fff;
}




/* Slider */

.slider-container{
  overflow-x: hidden;
  overflow-y: hidden;
}

.slide{
  float: left;
  padding:10px 0px;
  margin: 0px;
  overflow: hidden;
  height: 200px;
  transition-duration: 0.5s;
}
.slide:hover{
  transform: translate(0px, -4px);
  transition-duration: 0.1s;
}
.slide img{
  margin-right: 25px;
  pointer-events: none;
}

.html{
  font-size: 18px;
}

.html span{
  display: inline;
}

.slider-wrapper:first-child{
  padding-left: 10px;
  overflow: visible;
}

.slider-wrapper{
  
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;

  display: flex;
  flex-wrap: nowrap;

  width: fit-content;
  
  
    /* gpu */
  
  /* -webkit-overflow-scrolling: touch; */
}


  /* Hide bar 
  .slider-wrapper::-webkit-scrollbar {
}  
  
  */
  





/*

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.scrolling-wrapper .card {
  display: inline-block;
}

.scrolling-wrapper-flexbox {
  display: -webkit-box;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.scrolling-wrapper-flexbox .card {
  -webkit-box-flex: 0;
          flex: 0 0 auto;
  margin-right: 3px;
}


.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  height: 80px;
  margin-bottom: 20px;
  width: 100%;
  
}
.scrolling-wrapper::-webkit-scrollbar,
.scrolling-wrapper-flexbox::-webkit-scrollbar {
  display: none;
}



.card {
  flex: 0 0 auto;
  margin-right: 3px;
  height: 200px;
}


.scrolling-wrapper, .scrolling-wrapper-flexbox {
  height: 220px;
  margin-bottom: 20px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
 
}


.slider-container{

  width: 100%;
  overflow: hidden;
}

.slideable{

  -webkit-overflow-scrolling: touch;
  display: inline-block;
  width: 3000px;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  overflow-x: auto;
}

.slide{

  pointer-events: none;
  float: left;
  padding:10px 8px;
  margin: 5px;
  height: 200px;
}

*/


/* Sidebar */

.count{
  font-size: 10px;
  float: right;
  color: #666;
  padding-right: 15px;
}


/* List */

.dateBox{
width: 50px;
height: 70px;
padding: 5px 0px;
background-color: #1b4b59;

}
.dateBox span{
  display: block;;
  line-height: 20px;
  text-align: center;
  color: #f2eddd;
}

.locationwrapper{
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.metawrapper{
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.cluster{
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  text-align: center;;
}

.dot{
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 2px;
  border-radius: 3px;
  background-color: #fff;
}

.see-other{
  float: right;
  margin-top: -10px;
  font-weight: 600;
}


.wrapper{
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.small-wrapper{
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
}



.giftwrapper{
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, .34fr));
}

.card-wrapper{
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
}



.wrapBooks{
  display: flex;
  flex-direction: column;
  background-color: #eee;
 
}

.eventViewMore{
  height: 200px;
  background-color: #000;
}

.bannerWrap{
  overflow: hidden;
  text-align: center;
}

 .wrapper>* {
  background:none; 
  transition: 0.3s;
  padding:15px;
}

.wrapper>*:hover {
  background-color: #eee;
  transition: 0.5s;
  
}

.wrapper .listItem{
  cursor: pointer;  
  max-width: 700px;
} 


.small-wrapper>* {
  background:none; 
  transition: 0.3s;
  padding:15px;
}

.small-wrapper>*:hover {
  background-color: #eee;
  transition: 0.5s;
  
}

.small-wrapper .listItem{
  cursor: pointer;  
  max-width: 700px;
} 


.cap{
  width:100%;
  height: 1px;
  opacity: 0.1;
  position: relative;
  z-index: 1;
  margin-top: -1px;
  display: block;
  background-color: #fff;
}


/* list item */

.no-badge{
  background: none;
  border: none;
}

.ItemSidebar{
  padding: 0px 10px;
}
.ItemSidebar img{
  max-width: 120px;;
  padding: 10px 0px;
}


.sidebar{
  background-color: #fff;
  padding-right: 20px;
}

.count-badge {
  background-color: '#52c41a';
}


/* Typeography */

h2{
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  
}
h2 span{
  display: block;
  font-size: 18px;
  margin-top: -2px;
  font-weight: normal;
  line-height: 22px;
  padding: 5px 0px;
  color: #444;
}


h3{
  font-size: 18px;
  line-height: 18px;
}
h3 span{
  padding-top: 0px;
  display: block;
  font-size: 14px;
  color: #444;
  font-weight: normal;
}


h4{
  font-size: 14px;
  line-height: 14px;
}
h4 span{
  font-weight: normal;
  padding-top: 5px;
  display: block;
  color: #444;
}


.single_line{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w100{
  max-width: 100px;
}

.w150{
  max-width: 150px;
}

.w200{
  max-width: 200px;
}

.w250{
  max-width: 250px;
}

.w300{
  max-width: 300px;
}

.resultsTable table > thead > tr:first-child th{
  padding: 5px 10px;
}

.resultsTable .ant-table-tbody table > tr > td{
  padding: 5px 10px;
}

.resultsTable .ant-table-thead > tr > th, .resultsTable .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .resultsTable .ant-table tfoot > tr > td{
  padding: 10px 10px;
}

.ant-table-tbody > tr.ant-table-row:hover > td{
  background-color: initial;
}


.resultsTable .ant-table-tbody > tr.ant-table-row:hover > td{
  background-color: #eee;
}




/* Details */

.details-box div{
  display: flex;
  align-items: center; ;
  min-height: 40px;
  justify-content: space-between;
  padding: 0px 15px;
  text-align: right;
}
.details-box div:nth-of-type(odd) {
  background-color:#eee;

  }
      
  .details-box div:nth-of-type(even) {
  background-color:#fff;
  }
  

.header-wrap{
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
}

.shadow-slider{
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.17);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.17);
  box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.17);
  margin: 0 auto;
  max-height: 100%;

}

.shadow{
  -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.27);
  -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.27);
  box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.27);
  width: 95%;
  max-width: 313px;
  margin: 0 auto;
}


.imageNotFound{
  width: 90%;
  max-width: 313px;
  margin: 0 auto;
}

.shadow-light{
  -webkit-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.17);
  -moz-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.17);
  box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.17);
  width: 90%;
  max-width: 313px;
  margin: 0 auto;
}

.shadow-small{
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.17);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.17);
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.17);
  width: 90%;
  max-width: 313px;
  margin: 0 auto;
}


.light{
  -webkit-box-shadow:none; 
  -moz-box-shadow:none; 
  box-shadow:none; 
  width: 90%;
  max-width: 313px;
  margin: 0 auto;
}

.light-cart{
  -webkit-box-shadow:none;
  -moz-box-shadow: none;
  box-shadow: none;
 
  max-height: 100px;
  margin: 0 auto;
  
}

.shadow-cart{
  -webkit-box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.17);
  -moz-box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.17);
  box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.17);
 
  max-height: 100px;
  margin: 0 auto;
  
}



.image{
  max-height: none;
}

/* helpers */

.small-btn{
  font-size: 12px;
}

.nav {
  color:inherit;
  text-decoration: none;
  max-width: 450px;
 }
.nav:hover {
  color:inherit;
  text-decoration: none;
 }



hr{
  border:none; 
  display: block;
  height: 1px;
}

.pull-right{
  text-align: right;
  width: 100%;;
}

.ant-layout{
  background: none;
}
.container_wrap{
  max-width: 1248px;
  margin:0px auto;
}

.container{
  padding: 20px 20px;
  max-width: 1248px;
  margin:0px auto;
  background-color: #fff;

  /* -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1); */

}


.inside_container{
  padding: 10px 10px;
  max-width: 1248px;
  margin:0 auto;
  background-color: #fff;
  margin-top: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

}





.softShadow{
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
  
}

.noshadow{
  -webkit-box-shadow:none;
  -moz-box-shadow:none;
  box-shadow: none;

}

/* Overwrites */



html, body{
  min-height: 100%;
}

body{
  background-color: #eee;
  margin: 0;
   font-family: "Default";
  

}

.provider-hover{
  background-color: #fff;
}
.provider-hover:hover{
  background-color: #f7fff6;
}
.provider-hover-selected{
  background-color: #f7fff6;
}

.poweredby{
  opacity: 0.6;
  padding: 10px;
}

.poweredby span:first-child{
  font-size: 8px;
  font-style: italic;
}
.poweredby span:last-child{
  font-size: 12px;
  font-weight: bold;
}

.ant-drawer-header{
  border-radius:0px;
}

.ant-drawer-body{
  padding: 0px;
}
.indent > .ant-checkbox-wrapper{
  max-width: 210px;
}

.bm-pop-up{
  pointer-events: all;
}

.wait{
  pointer-events: none;
  opacity: 0.5;
  transition-duration: 0s;
}
.ready{
  pointer-events: inherit;
  opacity: 1;
  transition-duration: 0.5s;
}

.ant-btn-text:hover, .ant-btn-text:focus{
  background:none; 
 
 
}


.ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.4;
}

.ant-carousel .slick-dots-bottom{
  bottom: -34px;
}
.ant-carousel .slick-dots li.slick-active{
  width: 16px;

}
.ant-carousel .slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
  background-color: #000;
}




/* MOBILE DOTS */

.slick-dots > .mobileDots{
  margin-top: 10px;
  margin-bottom: 20px;
}
.slick-dots > .mobileDots li{
  width: 14px;
  height: 0px;
  top: 25px;

}
.slick-dots > .mobileDots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #aaa;
  opacity: 1;
}

.mobileDots  .slick-dots-bottom{
  bottom: 50px;
}
.slick-dots > .mobileDots li.slick-active{
  width: 14px;
}
.slick-dots > .mobileDots li.slick-active button {
  width: 10px;
  height: 10px;
  opacity: 1;
  border: 1px solid #aaa;
  background-color: #888;
}

.siteModal > .ant-modal > .ant-modal-content > .ant-modal-body{
  padding:24px;
}

.float-flex{
  display: flex;
  justify-content: space-between;
}

.paginate {
  margin: 0px 0px;
}

.ant-pagination-total-text{
  display: none;
}

.ant-table-pagination {
  padding-right: 20px;
}


.ant-pagination-next{
  padding-right: 10px;
}

.ant-pagination-prev{
  float: left;
  padding-left: 10px;
}

input[type=text], input:not([type]), textarea{
  border:1px solid #ddd; 
}
.ant-input-number-input input[type=text], input:not([type]), textarea{
  border:none;
}


/* Always show number stepper */
.ant-input-number-handler-wrap{
 opacity: 1;
}

.ant-input-prefix{
  color: #d9d9d9;
}
.desktopLogoNav {
  flex-flow: nowrap;
}

.mobile_drawer .ant-drawer-body{
  padding:0px;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu{
  padding: 0px 19px 0 20px;
}

.headerShadow {
  box-shadow: 0 5px 5px -1px rgba(0,0,0,.15);
}

.ant-menu.ant-menu-dark .ant-menu-item-selected{
  /* font-weight: bold; */
}

.ant-menu-dark.ant-menu-horizontal .ant-menu-submenu-selected{
  /* background-color: #1890ff;;
  color: #fff;
  font-weight: bold; */
}


.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
  font-weight: bold;
  border-top: 1px solid #eee;
  color: #555;
}

.hide-header.ant-collapse-item > .ant-collapse-header{
  padding: 0px;
  border-bottom: none;
  border-top: none;
  margin-top: 3px;
}

.break-header.ant-collapse-item > .ant-collapse-header{
  padding-bottom: 0px;
  margin-top: 5px;
  margin-bottom: 15px;
  border-bottom: 1px dashed #eee;
  border-top: none;

}


.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
  padding-top: 0px;
  padding-bottom: 0px;
}


.inline-button {
  background: none!important;
  border: none;
  padding: 0px 0px!important;
 
  text-decoration: none;
  cursor: pointer;
  outline:none;
  -webkit-box-shadow:none;
  box-shadow: none;

}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: #fff;
  line-height: 64px;
  background: #fff;
}

/* Footer */

.sitenav-wrap{
margin-bottom: 20px;
background-color: #001529;
}

.site-footer{
  background-color: #001529;
  min-height: 150px;
  margin-top: 20px;
}

.site-banner{
  margin: 0 auto;
  margin-top: -20px;
  /* max-width: 1248px; */
}

.ant-layout-sider-children{
  overflow: hidden;
}


/* */

.ant-dropdown{
  z-index: 9;
}
.mobileLogoNav{
  padding:3px 7px;
  height: 54px;
  background-color: #fff;
  align-items: center;
  position: relative;
  z-index: 1;;


}

.inputNum input{
  padding: 4px 0px;
  border-left: none;
  border-right: none;
}

.inputNum .ant-input-group .ant-input:hover{
  border: "1px solid #ddd";
}

.inputNum .ant-input:hover, .inputNum .ant-input:focus {
  border-color: #ddd;
  border-right-width: 1px !important;
}

.inputNum .ant-input:focus{
  box-shadow: none;
}

.inputNum{
  background-color: "#fff";
}

.inputNum .ant-input-group-addon{
  cursor: pointer;
  background-color:#fff;
  padding: 0px;

}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child{
  left: 0px;
}


.mobileNavShadow{
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
  position: relative;
  z-index: 0;;
}

.mobileSearchBG{
  background-color: #fff;
  display: none;
}

.desktopLogoNav{
  padding:10px 7px;
  height: 64px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}



.desktopLogoNav .logo{
  max-height: 40px;
  max-width: 300px;
  padding-left: 5px;
  display: flex;
  justify-content: left;
  
}

.temp{
  padding: 7px;
  display: inline;
}

.fixed{
  position: fixed;
}

.mobileLogoNav .logo{
  max-height: 30px;
  max-width: 130px; 
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 4px auto; */ 
}



.icon-btn{
  color: #000;
  font-size: 19px;
  background:none;
  padding: 13px;
}

.btn{
  text-decoration:none;
  color: #fff;
}


#clearent_button{
  top: -65px;
  position: relative;
  left: 25px;
}

.Clearent .btn-primary{
  background-color: #000 !important;
  width: 220px !important;
  border: none !important;
  border-radius: none !important;
}

.Clearent .btn-group-lg>.btn{
  border-radius: none !important;
}

.Clearent .btn-lg{
  border-radius: none !important;
}
.open {
 margin-top: 0;
}
.close{
  margin-top: -40px;
  z-index: -10;
  position: absolute;
}



.hide{
  display: inline;
}

.hide-box{
  display:-webkit-box;
  display: -moz-box;
}

.show{
  display: none;
}
.hide-block{
  display: block;
}
.show-block{
  display: none;
}

.adjust-margin{
  margin:0;

}

.adjust-padding{
  padding:0px 20px;
}

.adjust-padding-invert{
  padding:0px 0px;
}



.site-footer p, .site-footer h4 {
  color: #fff;
 
}
.site-footer{
  
  justify-content: center; 
  align-content: center; 
}

.site-footer-item{
  
  
  text-align: center;
}


.hide-lg{
  display: block;
}
.show-lg{
  display: none;
}


.itemDetailsSider {

  width: 310px;
  min-width: 310px;
  background-color: #fff;
 
}

.viewAll{

  width: 125px;
  height: 175px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:#fbfbfb ;
 
}


.responsiveSider {
  background-color: #fff;
  flex-direction: row;
}

.responsiveSummary{
  width: 290px;
  min-width: 290px;
  padding-left: 20px;
}

.responsiveSummaryLeft{
  width: 290px;
  min-width: 290px;
  padding-right: 20px;
}



.full-image{
  display: grid;
}

.ant-image-preview-operations-operation .anticon-rotate-left{
  display: none !important;
}
.ant-image-preview-operations-operation .anticon-rotate-right{
  display: none !important;
}

.ant-image-preview-operations-operation .anticon-zoom-in{
  display: none !important;
}

.ant-image-preview-operations-operation .anticon-zoom-out{
  display: none !important;
}

.ant-image-preview-mask{
  background-color: rgba(0, 0, 0, 0.8)
}

.ant-image-mask-info{
  display: none !important;
}

.ant-modal{

  width: "90%";
  max-width: "1000px";
  top: 40px;

}

.center{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-menu-submenu-title .anticon{
  transition: none;
}

.msg .ant-alert-description{text-align: center; width: 100%;}

.halfWidth {
max-width: 50%;
}

.cart-icon{
  transform: scale(1);
}

.row-colapse-reversed{
  flex-direction: row-reverse !important;
}






@media only screen and (max-width: 992px) {

  .row-colapse-reversed{
    flex-direction: column-reverse !important;
  }
  .cart-icon{
    transform: scale(.85);
  }

 
  

  .halfWidth {
    max-width: 100%;

    padding-left: 10px;
    padding-right: 10px;
    }
 

  .responsiveSider.ant-layout.ant-layout-has-sider {
    background-color: #fff;
    flex-direction: column;
  }

  .responsiveSummaryLeft{
    width: 100%;;
    padding-left:20px;
    padding-right: 20px;
  }
  .responsiveSummary{
    width: 100%;;
    padding-left:0px;
    padding-right: 0px;
  }

  .itemDetailsSider {
    min-width: 100%;
    flex-direction: column;
  }
 
  
  .hide-lg{
    display: none;
  }
  .show-lg{
    display: block;
  }

  .site-footer{
    background-color: #001529;
    min-height: 150px;
    margin-top: 0px;
   
  }

}

.bm-component p:last-child{
  margin-bottom: 0px;
}

.bm-cal .ant-picker-content thead{
  display: none;
}

.card-summary .ant-card-body{
  padding: 10px;
}

.hideCard .ant-card-body{
  padding: 24px;
}

.hideCard {
  border:1px solid inherit;
}

.bm-wrap {
  flex-wrap: nowrap;
}

.bm-stack {
  flex-direction: row;
}

.bm-stack-flip {
  flex-direction: row-reverse;
}

.react-player{
  position: absolute;
  top: 0;
  left: 0;
}

.player-wrapper{
  position: relative;
  padding-top: 56.25%;
}

.mbl-btn{
  padding : 4px 15px;
}

@media only screen and (max-width: 716px) {


   .ant-menu-item,
   .ant-menu-submenu-title {
    padding: 8px 0px !important; /* Set desired padding */
    margin: 0 !important;
  }
  
  /* Remove indentation for submenu items */
   .ant-menu-submenu .ant-menu-item,
   .ant-menu-submenu .ant-menu-submenu-title {
    padding-left: 0px !important; /* Matches top-level items padding */
  }


  .ant-menu-submenu .ant-menu-submenu-title .ant-menu-title-content {
   text-indent: 20px !important;
 }
 
  
  /* Remove default submenu indentation */
   .ant-menu-submenu {
    padding: 0 !important;
    margin: 0 !important;
  }
  
  /* Optional: Remove the bottom border on hover */
   .ant-menu-item::after,
   .ant-menu-submenu-title::after {
    display: none !important;
  }


 
  /* .ant-menu-submenu-inline{
    padding: 0px !important;
  } */
  

  .mbl-btn{
    padding : 4px 10px;
  }

.bm-footer-flex{
  flex-direction: column;
  gap: 15px !important;
}

.bm-footer-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
}





  .bm-wrap {
    flex-wrap: wrap;
  }
  .bm-stack {
    flex-direction: column;
  }
  .bm-stack-flip {
    flex-direction: column-reverse;
  }

  .search_bg .logo {
    padding-left: 15px;
    max-width : 100% !important;
  }
  
  .see-other{
    float: right;
    margin-top: -20px;
    font-weight: 600;
  }

  .site-banner{
    margin: 0 auto;
    margin-top: 0px;
    /* max-width: 1248px; */
  }

  .gallery-img{
    max-width: 270px;
    max-height: 270px;
    margin: 0 auto;
    object-fit: contain;
    object-position: 50% 50%;
  }

  .halfWidth {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    }
 
  

  .header_search .ant-input, .header_search .ant-input:hover, .header_search .ant-input:focus{

    box-shadow: none;
    border-left: 1px solid;
    border-right: 1px solid;
    --antd-wave-shadow-color: none;
  }
  
  .header_search .ant-btn, .header_search .ant-btn:hover, .header_search .ant-btn:focus{
  
    box-shadow: none;
    --antd-wave-shadow-color: none;
    border-left: 1px solid;
    border-right: 1px solid;
   
  }
 
  .gallery-thumb-slider{
    margin: 10px 0px;
  }

  .addressItem{
    width: 100%;
  }

  .hideCard .ant-card-body{
    padding: 0px;
  }

  .hideCard {
    border:none;
  }

  .itemDetailsSider {
    width: auto;
    background-color: #fff;
  }

  .full-image .ant-image-mask-info{
    display: none !important;
  }

  .full-image{
    display: inherit;
    margin-bottom: 20px;
  }

  .ant-modal{

    width: "100%";
    top: 0;
  
  }
  
  .siteModal > .ant-modal > .ant-modal-content > .ant-modal-body{
  
    padding:20px;
    
  }

  .gallery .img{
    background-color: #000;
    width: "100%";
    height: 400px;
    background-repeat: no-repeat;
    background-size: contain; 
    background-position: center;
  }
  
  .wrapper{
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin:-20px;
  }

  .small-wrapper{
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin:-20px;
  }

  .card-wrapper{
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin:-20px;
  }

  .adjust-padding{
    padding:0px 0px;
  }

  .adjust-padding-invert{
    padding:0px 20px;
  }

  .adjust-margin{
    margin:0px -20px;
  }

  
  .hide{
    display: none;
  }
  .hide-box{
    display:none;
  }

  .show{
      display: inline;
  }

  .hide-block{
    display: none;
  }
  .show-block{
    display: block;
  }

  .image{
    max-height: 200px;
    width: auto;
  }

  .imagewrap{
    width: 100%;
    background-color: aquamarine;
  }
  .container_wrap{
    margin:0 auto;
  }

  .inside_container{
    padding: 0px 0px;
    max-width: 1170px;
    margin:0 auto;
    background-color: #fff;
    margin-top: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

  }

  .container{
    padding: 20px 20px;
    max-width: 1170px;
    margin:0 auto;
    background-color: #fff;
    margin-top: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

  }
 

  .ant-card-head{
    background-color: "#e00";
  }
 
.BMHomepageBanner {	position: relative;	width: 100%;	margin: auto;	font-size: 14px;	font-weight: bold;}.BMHomepageBanner > img {	width: 100%;	height: auto;}.BMHomepageBanner > .content {	position: absolute;	width: 100%;	height: 100%;	top: 0px;	left: 0px;	text-align: center;}.BMHomepageBanner .tn-button {  font-family: 'ApercuMono';  font-weight: 400;  font-style: normal;	display: block;  transition: all 0.3s ease 0s;  -moz-transition: all 0.3s ease 0s;  -webkit-transition: all 0.3s ease 0s;  text-decoration:none;  letter-spacing: 3px;  cursor: pointer;  line-height:1.6em;	padding: 18px 27px;}.BMHomepageBanner > .content,.BMHomepageBanner > .content > div {	display: block;}.BMHomepageBanner .tagline {	background-color: rgba(13, 21, 43, 0.9);	color: #ffffff;	line-height: 1.5;	font-size: 2.74em;	display: inline-block;}.BMHomepageBanner .learn-more-button-wrapper > a {	background-color: #1581E9;	color:#ffffff !important;}.BMHomepageBanner .learn-more-button-wrapper > a:hover {	color: #0D152B !important;	background-color: #25E299;}.BMHomepageBanner .tour-button-wrapper > a {  background-color: #8f3fe4;  color:#ffffff !important;}.BMHomepageBanner .tour-button-wrapper > a:hover {  color: #0D152B !important;	background-color: #25E299;}@media not screen and (min-width: 1000px) {	.BMHomepageBanner .valignhack {		display: none;	}  .BMHomepageBanner .tagline-wrapper > .tagline {		display: block;	}  .BMHomepageBanner > .content {		position: relative;	}.BMHomepageBanner > .content .tagline-wrapper .tagline {	padding: 1em 0;}}@media only screen and (min-width: 1000px) {	.BMHomepageBanner > .content {		display: flex;	}	.BMHomepageBanner > .content > div {		flex: 1;	}  .BMHomepageBanner .tagline-wrapper {		padding-top: 2em	}	.BMHomepageBanner .tn-button {		display: inline-block;	}	.BMHomepageBanner .tour-button-wrapper {		vertical-align: bottom;	}.BMHomepageBanner > .content .tagline-wrapper .tagline {	padding: 1em 1.5em 1.5em 1.5em;}}


}
